import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Waiting from "./Waiting";

class Panel extends Component {
    render() {
        const {
            refFunc,
            title,
            subtitle,
            fullColor,
            borderColor,
            showHeaderDivider,
            isPanelTable,
            tools,
            isProcessing,
            ...rest
        } = this.props;

        const classes = ['panel'];

        if (fullColor) {
            if (fullColor === 'gray') {
                classes.push('panel-full');
            } else {
                classes.push(`panel-full-${fullColor}`);
            }
        } else if (borderColor) {
            classes.push(`panel-border-color panel-border-color-${borderColor}`);
        } else {
            classes.push('panel-default');
        }

        if (isPanelTable) {
            classes.push('panel-table');
        }

        return (
            <div className={classes.join(' ')} ref={refFunc} {...rest}>
                {title &&
                <div className={`panel-heading ${showHeaderDivider? 'panel-heading-divider' : ''}`}>
                    {title}

                    {subtitle &&
                    <div className="panel-subtitle">{subtitle}</div>}

                    {tools &&
                    <div className="tools dropdown">
                        {tools}
                    </div>}
                </div>}
                <div className={`panel-body ${isPanelTable ? 'table-responsive' : ''}`}>
                    <Waiting isProcessing={isProcessing}>
                        {this.props.children}
                    </Waiting>
                </div>
            </div>
        );
    }
}

Panel.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    refFunc: PropTypes.func,
    showHeaderDivider: PropTypes.bool,
    borderColor: PropTypes.oneOf(['default',  'primary', 'success', 'warning', 'danger', 'gray', 'dark']),
    fullColor: PropTypes.oneOf(['default',  'primary', 'success', 'warning', 'danger', 'gray', 'dark']),
    isPanelTable: PropTypes.bool,
    tools: PropTypes.element,
    isProcessing: PropTypes.bool,
};

Panel.defaultProps = {
    isProcessing: false,
};

export default Panel;
