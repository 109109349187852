import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import FormGroup from './FormGroup';

export default class Input extends Component {
  getErrorMessage() {
    const { error, value } = this.props;

    if (value && typeof error === 'function') {
      // It is a validation function.
      return error(value);
    }

    if (error && typeof error !== 'function') {
      // It is a message or an array of messages
      return error;
    }
  }

  render() {
    const {
      id,
      label,
      mask,
      addOn,
      buttonAddOn,
      help,
      helpAsText,
      refFunc,
      error,
      ...rest
    } = this.props;

    let input = mask ? (
      <InputMask
        {...rest}
        id={id}
        ref={refFunc}
        mask={mask}
        className="form-control"
      />
    ) : (
      <input {...rest} id={id} ref={refFunc} className="form-control" />
    );

    if (addOn) {
      input = (
        <div className="input-group">
          {input}
          <span className="input-group-addon">{addOn}</span>
        </div>
      );
    } else if (buttonAddOn) {
      input = (
        <div className="input-group">
          {input}
          <div className="input-group-btn">{buttonAddOn}</div>
        </div>
      );
    }

    return (
      <FormGroup
        label={label}
        help={help}
        helpAsText={helpAsText}
        error={this.getErrorMessage()}
        id={id}
      >
        {input}
      </FormGroup>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  help: PropTypes.string,
  helpAsText: PropTypes.bool,
  /**
   * An error message or a list of error messages or a function. If it is a
   * function, must return the error messages or null.
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  /**
   * Input mask implemented by react-input-mask.
   */
  mask: PropTypes.string,
  refFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  addOn: PropTypes.element,
  buttonAddOn: PropTypes.element,
};
