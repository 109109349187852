import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Ajax from "../../common/ajax";
import Config from "../../config";
import Select from "../../common/components/form/Select";
import Waiting from "../../common/containers/Waiting";

class ListSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            processing: false,
        };
    }

    componentDidMount() {
        this.setState(state => ({
            ...state,
            processing: true,
        }));

        Ajax.get(`${Config.apiHost}mailing/lists/`).done(lists => {
            this.setState(state => ({
                ...state,
                lists,
                processing: false,
            }));
        });
    }

    render() {
        return (
            <Waiting isProcessing={this.state.processing}>
                <Select
                    label={this.props.label}
                    name="listId"
                    value={this.state.listId}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    required={this.props.required}
                    options={this.state.lists.map(i =>
                        ({label: i.name, value: i.id}))}
                />
            </Waiting>
        );
    }
}

ListSelect.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

ListSelect.defaultProps = {
    label: 'Lista',
    required: true,
};

export default ListSelect;
