import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Select extends Component {
  getErrors() {
    if (Array.isArray(this.props.error)) {
      return this.props.error.map((e) => {
        return (
          <p key={e} className="help-block">
            {e}
          </p>
        );
      });
    } else if (this.props.error) {
      return <p className="help-block">{this.props.error}</p>;
    }
  }

  render() {
    const { label, help, options, refFunc, dividerLineAt, ...rest } =
      this.props;

    let hasError = false;

    if (Array.isArray(this.props.error)) {
      hasError = this.props.error.filter((e) => !!e).length > 0;
    } else {
      hasError = !!this.props.error;
    }

    return (
      <div
        className={`form-group ${hasError ? 'has-error' : ''}`}
        style={{ marginBottom: '20px' }}
      >
        {label && (
          <label className="" htmlFor={this.props.id}>
            {label}
            &nbsp;
            {help && <i className="icon mdi mdi-info-outline" title={help} />}
          </label>
        )}
        <select {...rest} ref={refFunc} className="form-control">
          <option value={''}>...</option>
          {options.map((opt, idx) => {
            if (idx + 1 === dividerLineAt) {
              return (
                <React.Fragment key={opt.value}>
                  <option disabled={true}>------</option>
                  <option value={opt.value}>{opt.label}</option>
                </React.Fragment>
              );
            }
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </select>
        {this.getErrors()}
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * An error message or a list of error messages.
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  refFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  dividerLineAt: PropTypes.number,
};

export default Select;
